<template>
  <MeetingModule>
    <c-radio-group
      class="tw-gap-2"
      :options="radioOptions"
      v-model="model.mail_student"
      @update:model-value="updateShareFeedbackAt"
    />

    <div
      class="tw-mt-5"
      v-if="
        model.mail_student &&
        schoolSettings.student_meeting_settings.settings.action_plan_version ===
          '1.0'
      "
    >
      <c-input
        v-model="model.mail_student_summary_text"
        type="textarea"
        autogrow
        label="Tekst voor 'Opmerking van leerling'"
      />
    </div>

    <div class="tw-mt-5" v-if="model.mail_student">
      <c-input
        v-model="model.mail_student_text"
        type="textarea"
        autogrow
        label="Instructie-mail voor de leerling"
      />
    </div>
  </MeetingModule>
</template>

<script setup>
import { onMounted, ref, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { useSettingsStore } from "store/modules/settingStore";
import { useMeetingStore } from "store/modules/meetingStore.js";
import { storeToRefs } from "pinia";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// Initialize setting store
const settingStore = useSettingsStore();
const { getSchoolSettings } = settingStore;
const { schoolSettings } = storeToRefs(settingStore);

const meetingStore = useMeetingStore();
const { clearShareFeedbackAt } = meetingStore;

// Refs
const radioOptions = ref([
  {
    label: "Inschakelen",
    value: 1,
  },
  {
    label: "Uitschakelen",
    value: 0,
  },
]);

const updateShareFeedbackAt = () => {
  if (!model.value.mail_student) clearShareFeedbackAt();
};

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value != null;
};

onMounted(async () => {
  await getSchoolSettings();

  if (model.value?.mail_student === undefined) {
    model.value.mail_student = null;
  }

  if (model.value?.mail_student_summary_text === undefined) {
    model.value.mail_student_summary_text =
      "Wat ging er volgens jou goed de afgelopen periode?";
  }

  if (model.value?.mail_student_text === undefined) {
    model.value.mail_student_text =
      "Hierbij ontvang je van ons de tussentijdse feedback. Graag ontvangen we het actieplan van je voor de volgende bespreking met de mentor of de ouderavond.";
  }

  modelUpdate(model.value.mail_student);
});

watchEffect(() => {
  modelUpdate(model.value.mail_student);
});
</script>
