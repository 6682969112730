import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import FloatingVue from "floating-vue";
FloatingVue.options.themes.tooltip.triggers = ["hover", "focus", "touch"];
import "floating-vue/dist/style.css";
import Vue3Lottie from "vue3-lottie";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

// Quasar
import Quasar from "plugins/quasar";

// Import custom styles
import "styles/main.scss";

// Wrapper components
import WrapperComponents from "plugins/wrapper-components";
import meetingModules from "plugins/meeting-modules";

// This will enable Sentry error logging
import Sentry from "plugins/sentry";

// Layouts
import layouts from "@/layouts";

// @todo: Move to a sepearate file
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

//User middleware
import axios from "axios";
axios.defaults.baseURL = import.meta.env.VITE_LARAVEL_URL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      let check = localStorage.getItem("401_count");

      if (check) {
        localStorage.removeItem("401_count");

        window.location.reload();
      }
    }
    return response;
  },
  async (error) => {
    console.log(error);

    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user_token");
      localStorage.removeItem("refresh_token");
      localStorage.clear();

      window.location.reload();
    }

    return Promise.reject(error);
  },
);

const pinia = createPinia();

const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .use(store)
  .use(FloatingVue)
  .use(layouts)
  .use(Quasar)
  .use(Sentry)
  .use(WrapperComponents)
  .use(meetingModules)
  .use(autoAnimatePlugin)
  .use(Vue3Lottie);

//Global Variables
let laravelUrl = import.meta.env.VITE_LARAVEL_URL;
app.config.globalProperties.$laravelUrl = laravelUrl;
app.config.globalProperties.$laravelImageUrl = "https://laravel.catwise.nl";

if (localStorage.getItem("user")) {
  let userId = JSON.parse(localStorage.getItem("user")).id;
  app.config.globalProperties.$userId = userId;
}

let schoolId = localStorage.getItem("school_id");
app.config.globalProperties.$schoolId = schoolId;

let schoolName = localStorage.getItem("school_name");
app.config.globalProperties.$schoolName = schoolName;

let studentId = localStorage.getItem("student_id");
app.config.globalProperties.$studentId = studentId;

if (localStorage.getItem("user")) {
  let role = JSON.parse(localStorage.getItem("user")).role[0];
  if (!role) {
    localStorage.clear();
  }
  app.config.globalProperties.$role = role;
} else {
  localStorage.clear();
}

let studentNumber = localStorage.getItem("student_number");
app.config.globalProperties.$studentNumber = studentNumber;

let employeeId = localStorage.getItem("employee_id");
app.config.globalProperties.$employeeId = employeeId;

let employeeShortage = localStorage.getItem("employee_shortage");
app.config.globalProperties.$employeeShortage = employeeShortage;

if (import.meta.env.NODE_ENV === "development") {
  app.config.performance = true;
}

app.mount("#app");
