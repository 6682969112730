import schoolApi from "../../api/schoolApi";

/**
 * State of the schools module.
 * @returns {Object} The state object.
 */
const state = () => ({
  /**
   * The current school data.
   * @type {Object|null}
   */
  school: null,
});

const actions = {
  /**
   * Fetches the school data by ID and commits it to the state.
   * @param {Function} commit - The Vuex commit function.
   * @param {number} id - The ID of the school to fetch.
   * @returns {Promise<void>}
   */
  async getSchool({ commit }, id) {
    const school = await schoolApi.getSchool(id);
    commit("setSchool", school);
  },
};

const mutations = {
  /**
   * Sets the school data in the state.
   * @param {Object} state - The current state.
   * @param {Object} school - The school data to set.
   */
  setSchool(state, school) {
    state.school = school;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
