<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
    style="z-index: 999"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="tw-flex tw-min-h-screen tw-items-start tw-justify-center tw-px-4 tw-pb-20 tw-pt-20 tw-pt-4 tw-text-center sm:tw-block sm:tw-p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "tw-ease-out tw-duration-300"
          From: "tw-opacity-0"
          To: "tw-opacity-100"
        Leaving: "tw-ease-in tw-duration-200"
          From: "tw-opacity-100"
          To: "tw-opacity-0"
      -->
      <div
        class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
        aria-hidden="true"
        @click="closeModal"
      />

      <!-- This element is to trick the browser into centering the modal tw-contents. -->
      <span
        class="tw-hidden sm:tw-inline-block sm:tw-h-screen sm:tw-align-middle"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="tw-relative tw-inline-block tw-w-full tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-align-bottom tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-max-w-2xl sm:tw-align-middle"
      >
        <div
          class="tw-w-full tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4"
        >
          <div class="tw-h-64 tw-w-full tw-py-14 tw-text-center" v-if="loading">
            <img src="/images/icons/loading.gif" class="tw-inline tw-w-24" />
          </div>
          <div
            class="tw-h-64 tw-w-full tw-py-10 tw-text-center"
            v-if="successMessage"
          >
            <img
              src="/images/checkmark-gif.gif"
              class="tw-mb-4 tw-inline tw-w-24"
            />
            <p class="tw-font-bold">Bedankt voor de feedback!</p>
            <p class="font-gray-700 tw-mt-2">
              Dit venster sluit automatisch binnen 5 seconden of klik
              <span
                class="tw-cursor-pointer tw-text-blue-700 tw-underline"
                @click="closeModal"
                >hier</span
              >
            </p>
          </div>
          <div v-else-if="!loading">
            <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left">
              <h3 id="modal-title" class="tw-text-gray-900"></h3>
              <p
                class="md: tw-mt-2 tw-text-center tw-font-bold tw-tracking-wide"
              >
                Hoe waarschijnlijk is het dat je Catwise zou aanbevelen aan
                collega's, familie en/of vrienden die actief zijn in het
                onderwijs?
              </p>
              <div class="tw-mt-2">
                <div class="tw-py-7">
                  <!-- stars -->
                  <div
                    class="tw-flex tw-justify-center tw-pb-4"
                    @mouseleave="resetRating()"
                  >
                    <div
                      v-for="i in 10"
                      :key="i"
                      class="tw-relative tw-h-10 tw-w-10 tw-cursor-pointer tw-text-center md:tw-h-14 md:tw-w-14"
                      @mouseover="setRating(i)"
                      @click="lockRating(i)"
                    >
                      <div
                        :id="'star-' + i"
                        class="tw-text-[#eab309]"
                        style="
                          transition: transform 0.8s;
                          transform-style: preserve-3d;
                        "
                      >
                        <q-icon
                          name="fa-regular fa-star"
                          size="40px"
                          v-if="i > hoverScore"
                        />

                        <q-icon
                          name="fa-solid fa-star"
                          size="40px"
                          v-if="i <= hoverScore"
                        />
                      </div>
                      <p
                        class="tw-absolute tw-left-3.5 md:tw-left-6"
                        :class="{
                          'tw-text-yellow-700': i <= nps.score,
                          'tw-font-bold': i === nps.score,
                        }"
                      >
                        {{ i }}
                      </p>
                    </div>
                  </div>

                  <div class="tw-flex tw-justify-between tw-tracking-wide">
                    <div>Zeer onwaarschijnlijk</div>
                    <div>Zeer waarschijnlijk</div>
                  </div>
                </div>
              </div>

              <!-- row -->
              <div class="tw-mt-2">
                <div class="tw-rounded-md tw-bg-blue-50 tw-p-4">
                  <div class="tw-mb-2">
                    <label class="tw-mb-2 tw-block tw-text-gray-700">
                      Wat is de voornaamste reden hiervan? (optioneel)
                    </label>
                    <textarea
                      class="focus:tw-shadow-outline tw-mb-3 tw-w-full tw-appearance-none tw-rounded tw-border tw-border-gray-300 tw-px-3 tw-py-2 tw-text-gray-700 focus:tw-outline-none"
                      placeholder="..."
                      v-model="nps.reason"
                    ></textarea>
                  </div>

                  <div>
                    <label class="tw-mb-2 tw-block tw-text-gray-700">
                      Wat zou beter kunnen? (optioneel)
                    </label>
                    <textarea
                      class="focus:tw-shadow-outline tw-w-full tw-appearance-none tw-rounded tw-border tw-border-gray-300 tw-px-3 tw-py-2 tw-text-gray-700 focus:tw-outline-none"
                      placeholder="..."
                      v-model="nps.improvement"
                    ></textarea>
                  </div>
                </div>

                <Alert :error="true" v-if="errorMessage"
                  >Kon niet worden verstuurd. Controleer de internetverbinding
                  en probeer het opnieuw.</Alert
                >
                <Alert :error="true" v-if="scoreError"
                  >Je moet verplicht een ster aanklikken...</Alert
                >

                <div
                  class="tw-mt-6 tw-flex tw-justify-between tw-border-t tw-pt-4"
                >
                  <div class="tw-flex-1 tw-text-left">
                    <button
                      class="tw-rounded-md tw-bg-blue-500 tw-px-4 tw-py-2 tw-text-white"
                      @click="submit"
                    >
                      Versturen
                    </button>
                  </div>
                  <div class="tw-flex-1 tw-text-right">
                    <button
                      class="tw-rounded-md tw-px-4 tw-py-2 tw-text-gray-700 tw-transition-colors hover:tw-bg-gray-100"
                      @click="closeModal"
                    >
                      Later opnieuw vragen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Alert from "@/components/global/Alert.vue";

export default {
  components: { Alert },
  data() {
    return {
      hoverScore: 0,
      nps: {
        score: 0,
        reason: "",
        improvement: "",
      },
      disableRatingHover: false,
      loading: false,
      successMessage: false,
      errorMessage: false,
      scoreError: false,
    };
  },
  methods: {
    submit() {
      this.scoreError = false;
      if (this.nps.score === 0) {
        this.scoreError = true;
        return;
      }

      let formData = new FormData();
      formData.set("nps", JSON.stringify(this.nps));

      let self = this;
      this.loading = true;
      axios
        .post("/api/nps", formData)
        .then(function () {
          self.successMessage = true;
          self.loading = false;

          setTimeout(function () {
            self.closeModal();
          }, 5000);
        })
        .catch(function () {
          self.errorMessage = true;
        });
    },
    setRating(score) {
      if (this.disableRatingHover) return;
      this.hoverScore = score;
    },
    resetRating() {
      this.hoverScore = this.nps.score;
    },
    lockRating(score) {
      let starEl = document.getElementById("star-" + score);
      this.disableRatingHover = true;
      let self = this;

      this.nps.score = score;

      starEl.style.transform = "rotateY(360deg)";
      setTimeout(function () {
        starEl.style.transform = "rotateY(0)";
      }, 200);

      setTimeout(function () {
        self.disableRatingHover = false;
      }, 1000);
    },
    closeModal() {
      localStorage.setItem("show_nps", "false");
      this.$emit("close-modal");
    },
  },
};
</script>
