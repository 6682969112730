<template>
  <span
    v-if="error || status === 'error'"
    class="tw-mt-2 tw-inline-flex tw-items-center tw-rounded tw-bg-secondary-200 tw-px-2.5 tw-py-2.5 dark:tw-bg-blue-200 dark:tw-text-blue-800"
    style="width: 100%"
  >
    <q-icon
      name="fa-solid fa-circle-info"
      size="40px"
      class="tw-mr-2 tw-shrink-0"
    />

    <slot />
  </span>

  <span
    v-if="warning || status === 'warning'"
    class="tw-mt-2 tw-inline-flex tw-items-center tw-rounded tw-bg-yellow-100 tw-px-2.5 tw-py-2.5 dark:tw-bg-blue-200 dark:tw-text-blue-800"
    style="width: 100%"
  >
    <q-icon
      name="fa-solid fa-circle-info"
      size="40px"
      class="tw-mr-2 tw-shrink-0"
    />

    <slot />
  </span>

  <span
    v-if="success || status === 'success'"
    class="tw-mt-2 tw-inline-flex tw-items-center tw-rounded tw-bg-tertiary-300 tw-px-2.5 tw-py-2.5 dark:tw-bg-blue-200 dark:tw-text-blue-800"
    style="width: 100%"
  >
    <q-icon
      name="fa-solid fa-circle-info"
      size="40px"
      class="tw-mr-2 tw-shrink-0"
    />

    <slot />
  </span>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
};
</script>
