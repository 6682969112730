import { createStore } from "vuex";
import user from "./modules/user";
import schools from "./modules/schools";

//components
import ratingIcons from "./modules/components/ratingIcons";

export default createStore({
  modules: {
    user: user,
    schools: schools,
    ratingIcons: ratingIcons,
  },
});
