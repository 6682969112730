<template>
  <MeetingModule :loading>
    <div class="tw-grid tw-grid-cols-12 tw-gap-2">
      <div class="tw-col-span-full">
        <c-checkbox
          :model-value="allSubjectsSelected"
          :label="toggleLabel"
          @click="toggleSelectAll"
        />
      </div>

      <div
        class="tw-col-span-6 md:tw-col-span-3 lg:tw-col-span-2"
        v-for="subject in subjects"
        :key="subject"
      >
        <c-checkbox :val="subject" v-model="model.schedules" :label="subject" />
      </div>
    </div>
  </MeetingModule>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref, computed, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import useHelpers from "composables/useHelpers";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 0;
};

// Helpers
const { schoolId } = useHelpers();

// Refs
const subjects = ref([]);
const loading = ref(true);

// TODO: To store
const getSubjects = async () => {
  try {
    const { data } = await axios.get(`api/school/${schoolId}/unique-schedules`);

    subjects.value = Object.values(data);
  } catch (error) {
    console.log("🚀 ~ getSubjects ~ error:", error);
  } finally {
    loading.value = false;
  }
};

// Return boolean if all classes are selected
const allSubjectsSelected = computed(() => {
  if (model.value.schedules) {
    return model.value.schedules.length === subjects.value.length;
  }
});

const toggleLabel = computed(() => {
  return allSubjectsSelected.value
    ? "Alle vakken deselecteren"
    : "Alle vakken selecteren";
});

const toggleSelectAll = () => {
  if (allSubjectsSelected.value) {
    model.value.schedules = [];
  } else {
    model.value.schedules = subjects.value.map((item) => item);
  }
};

onMounted(async () => {
  await getSubjects();

  if (model.value?.schedules == null) {
    model.value.schedules = [];
  }

  modelUpdate(model.value.schedules);
});

watchEffect(() => {
  modelUpdate(model.value.schedules);
});
</script>
