<template>
  <q-btn unelevated color="primary" v-bind="$attrs">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-btn>
</template>

<style lang="scss">
.q-btn {
  text-transform: none !important;

  .q-icon {
    font-size: initial;
  }

  &--outline {
    &:before {
      border: 1px solid $grey;
    }
  }

  .q-icon:not(.material-icons) {
    @apply tw-text-md;
  }
}
</style>
