import axios from "axios";

export default {
  async getSchool(id) {
    let res = await axios({
      method: "get",
      url: "/api/schools/" + id,
    });

    return res.data.data;
  },
};
