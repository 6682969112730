<template>
  <MeetingModule>
    <q-table
      class="sticky-header sticky-column tw-mb-4"
      flat
      bordered
      color="primary"
      separator="cell"
      :columns
      striped
      :rows="formattedStudents"
      virtual-scroll
      :virtual-scroll-slice-ratio-after="2"
      :virtual-scroll-slice-ratio-before="2"
      rows-per-page-label="Studenten per pagina"
      :rows-per-page-options="[0]"
      :no-data-label="`Geen studenten gevonden die voldoen aan: ${searchQuery}`"
    >
      <template #top>
        <div class="tw-flex tw-flex-1 tw-items-center tw-gap-4">
          <q-input
            class="tw-flex-1"
            borderless
            v-model="searchQuery"
            :debounce="300"
            label="Leerling zoeken..."
          >
            <template #append>
              <q-icon
                name="fas fa-magnifying-glass"
                size="xs"
                color="primary"
              />
            </template>
          </q-input>

          <c-input-select
            borderless
            class="tw-w-[250px]"
            label="Filter mentorklas"
            v-model="selectedClass"
            :options="mentorClasses"
            use-chips
            multiple
            options-dense
          >
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section side>
                  <c-checkbox
                    borderless
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label v-html="opt" />
                </q-item-section>
              </q-item>
            </template>
          </c-input-select>
        </div>
      </template>

      <template #header-cell="props">
        <q-th :props="props">
          <c-checkbox
            v-if="props.col.name !== 'formattedName'"
            :label="props.col.label"
            :model-value="!excludedSchedules.includes(props.col.name)"
            :disable="!isEditable"
            @update:model-value="
              () => isEditable && updateExcludeSchedule(props.col.name)
            "
          >
            <q-tooltip anchor="top middle" self="bottom middle">
              <template v-if="excludedSchedules.includes(props.col.name)">
                {{ props.col.label }} selecteren voor de huidige selectie
              </template>

              <template v-else>
                {{ props.col.label }} deselecteren voor de huidige selectie
              </template>
            </q-tooltip>
          </c-checkbox>

          <template v-if="props.col.name === 'formattedName'">
            {{ props.col.label }}
          </template>
        </q-th>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td key="formattedName" :props="props">
            {{ props.row.formattedName }}
          </q-td>

          <q-td v-for="schedule in schedules" :key="schedule" :props="props">
            <c-checkbox
              v-if="props.row.schedules.includes(schedule)"
              :disable="!isEditable"
              :val="schedule"
              v-model="model.schedules[props.row.id]"
            />
          </q-td>
        </q-tr>
      </template>

      <template #body-selection="scope">
        <c-checkbox :disable="!isEditable" v-model="scope.selected" />
      </template>
    </q-table>
  </MeetingModule>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed, watch } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { storeToRefs } from "pinia";
import { useStudentStore } from "store/modules/studentStore";
import { useMeetingStore } from "store/modules/meetingStore";
import { cloneDeep } from "lodash";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value && Object.keys(value).length > 0;
};

// Student store
const studentStore = useStudentStore();
const { students, mentorClasses, schedules } = storeToRefs(studentStore);

// Meeting store
const meetingStore = useMeetingStore();
const { isEditable, initialSchedules } = storeToRefs(meetingStore);

const updateExcludeSchedule = (value) => {
  if (excludedSchedules.value.includes(value)) {
    // Schedule should be enabled
    excludedSchedules.value = excludedSchedules.value.filter(
      (schedule) => !schedule.includes(value),
    );

    Object.entries(model.value.schedules).forEach(([studentId, schedules]) => {
      const currentStudent = students.value.find(({ id }) => id == studentId);

      if (currentStudent.schedules.includes(value)) {
        schedules.push(value);
      }
    });
  } else {
    // Schedule should be disabled
    excludedSchedules.value.push(value);

    const currentStudentSelectionById = formattedStudents.value.flatMap(
      ({ id }) => id,
    );

    // Remove the schedule by the students in the current selection
    Object.entries(model.value.schedules).forEach(([studentId, schedules]) => {
      if (currentStudentSelectionById.includes(parseInt(studentId))) {
        // Student has this schedule selected
        model.value.schedules[studentId] = schedules.filter(
          (schedule) => schedule !== value,
        );
      }
    });
  }
};

// Computed
const formattedSchedules = computed(() => {
  const usedSchedules = [
    ...new Set(formattedStudents.value.flatMap(({ schedules }) => schedules)),
  ];

  return schedules.value.filter((schedule) => usedSchedules.includes(schedule));
});

const columns = computed(() => {
  const cols = [
    {
      name: "formattedName",
      label: "Leerling naam",
      field: "formattedName",
      format: (val) => val,
      sortable: true,
      sort: (_a, _b, rowA, rowB) => {
        return rowA.last_name.localeCompare(rowB.last_name);
      },
      align: "right",
      index: 0,
    },
  ];

  formattedSchedules.value.forEach((schedule, index) => {
    cols.push({
      name: schedule,
      label: schedule,
      field: schedule,
      align: "center",
      index: index + 1,
    });
  });

  return cols;
});

const formattedStudents = computed(() => {
  if (!model.value.student_ids || model.value.student_ids.length === 0) {
    return [];
  }

  const query = searchQuery.value.toLowerCase().trim();
  let filteredStudents = students.value.filter(({ id }) =>
    model.value.student_ids.includes(id),
  );

  // Filter out selected mentor classes
  if (selectedClass.value && selectedClass.value.length > 0) {
    filteredStudents = filteredStudents.filter(({ mentor_class }) =>
      selectedClass.value.includes(mentor_class),
    );
  }

  if (!query) {
    return filteredStudents;
  }

  return filteredStudents.filter(
    ({ formattedName, student_number, mentor_class }) => {
      return (
        formattedName.toLowerCase().trim().includes(query) ||
        student_number.includes(query) ||
        mentor_class.toLowerCase().trim().includes(query)
      );
    },
  );
});

watch(
  () => model.value.student_ids,
  (student_ids) => {
    const schedules = model.value.schedules;

    // If not present, delete the key from the schedules object
    for (const key in schedules) {
      if (!student_ids.includes(parseInt(key))) {
        delete schedules[key];
      }
    }

    student_ids.forEach((studentId) => {
      if (!(studentId in model.value.schedules)) {
        const foundStudent = students.value.find(({ id }) => id == studentId);

        // Add the students scheduls but filter out excluded schedules
        model.value.schedules[studentId] = foundStudent.schedules.filter(
          (schedule) => !excludedSchedules.value.includes(schedule),
        );
      }
    });

    modelUpdate(model.value.schedules);
  },
  { deep: true },
);

// Refs
const excludedSchedules = ref([]);
const searchQuery = ref("");
const selectedClass = ref([]);

onMounted(async () => {
  if (model.value?.schedules == null) {
    model.value.schedules = {};
  }

  initialSchedules.value = cloneDeep(model.value.schedules);
  modelUpdate(model.value.schedules);
});

watchEffect(() => {
  modelUpdate(model.value.schedules);
});
</script>
